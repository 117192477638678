<template>
  <b-card v-if="CustomerData" class="card-transaction" no-body>
    <b-card-header>
      <div class="d-flex w-100 justify-content-between">
        <h5>Recent Clients</h5>
        <h5 class="d-flex"><span class="mr-4">Membeship</span>Actions</h5>
      </div>
    </b-card-header>
    <b-col
      md="6"
      lg="4"
      sm="12"
      v-if="isDataLoading"
      class="d-flex align-items-center justify-content-center"
      style="height: 400px; width: 100%"
    >
      <b-spinner label="Loading..."></b-spinner>
    </b-col>
    <b-card-body v-else>
      <div v-if="CustomerData.length === 0" class="text-center">
        <p>No recent client available.</p>
      </div>
      <div
        style="cursor: pointer"
        v-for="item in CustomerData"
        :key="item.mode"
        class="transaction-item mt-2"
      >
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              circle
              size="42"
              class="d-flex justify-content-center align-items-center"
            >
              <!-- variant="warning" -->
              <b-img
                v-if="item.profilephoto"
                fluid
                :src="item.profilephoto"
                alt=""
              />
              <feather-icon size="16" v-else icon="UserIcon" />
            </b-avatar>
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ item.f_name }}
          </span>
          <small>{{ item.specialiazed }}</small>
        </b-media>
        <div class="font-weight-bolder">
          <b-badge variant="light-warning" class="mr-3">
            {{ item.approve }}
          </b-badge>
          <span>
            <feather-icon
              class="mr-2"
              icon="XIcon"
              size="25"
              @click="openRejectModal(item.id)"
            />
            <feather-icon
              icon="CheckIcon"
              size="25"
              class="mr-2"
              @click="openModel(item.id)"
            />
            <feather-icon
              icon="EyeIcon"
              size="25"
              class="cursor-pointer"
              @click="handleView(item.id)"
            />
          </span>
        </div>
        <!-- ApproveClient('Approved', transaction.id) -->
      </div>

      <div class="w-100 d-flex justify-content-between p-1" v-if="CustomerData.length > 0">
        <!-- Pagination -->
        <div>
          <span class="text-nowrap">
            Showing {{ pagination.from }} to {{ pagination.to }} of
            {{ pagination.total }}</span
          >
        </div>
        <b-pagination
          :total-rows="paginationTotal"
          :per-page="per_page"
          v-model="paginationValue"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          @input="changePagination()"
        >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
        </b-pagination>
      </div>
    </b-card-body>
    <div>
      <b-modal
        v-model="RejectModal"
        no-close-on-backdrop
        hide-footer
        centered
        size="md"
      >
        <validation-observer ref="simple">
          <b-form ref="myFormMain">
            <b-row class="p-1">
              <b-col md="12" class="my-2 text-center">
                <!-- {{ CustomerData }} -->
                <h3
                  class="font-weight-bolder"
                  style="font-size:26px; font-family:Public Sans, sans's serif"
                >
                  Reject Client
                </h3>
              </b-col>
              <b-col cols="12">
                <b-form-group>
                  <label class="font-weight-bold mt-1" for="h-first-name"
                    >Reason For Rejection</label
                  >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Reject reason"
                  >
                    <b-form-input
                      type="text"
                      v-model="reject"
                      maxlength="100"
                      placeholder="Reason For Rejection"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- @click="Congo" -->
              <b-col md="12" class="d-flex justify-content-center mt-2">
                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  :disabled='saving'
                  @click.prevent="handleRejectForm()"
                  style="font-family: Public Sans"
                >
                  Submit
                </b-button>
                <b-button
                  type="reset"
                  @click="CloseModal"
                  variant="primary"
                  style="font-family: Public Sans"
                >
                  Cancel
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-modal>
    </div>
    <div>
      <b-modal
        v-model="selectPlan"
        no-close-on-backdrop
        hide-footer
        centered
        size="md"
      >
        <validation-observer ref="simpleRules">
          <b-form ref="myFormMain">
            <b-row class="p-1">
              <b-col md="12" class="my-2 text-center">
                <!-- {{ CustomerData }} -->
                <h3
                  class="font-weight-bolder"
                  style="font-size:26px; font-family:Public Sans, sans's serif"
                >
                  Approve Client
                </h3>
              </b-col>
              <!-- <b-col cols="12">
                <b-form-group>
                  <label class="font-weight-bold mt-1" for="h-first-name"
                    >Create UserID</label
                  >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="UserID"
                  >
                    <b-form-input
                      type="text"
                      v-model="UserID"
                      maxlength="20"
                      placeholder="UserID"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col> -->
              <b-col cols="12">
                <b-form-group>
                  <label class="font-weight-bold mt-1" for="h-contact"
                    >Password</label
                  >
                  <validation-provider
                    #default="{ errors }"
                    rules="required|password"
                    name="password"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="h-contact"
                        maxlength="20"
                        v-model="password"
                        :type="passwordIcon"
                        name="Password"
                        autocomplete="new-password"
                        placeholder="Password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePassword"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small v-if="passwordMismatch == true" class="text-danger"
                      >Password and Confirm Password must be same.</small
                    >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group>
                  <label class="font-weight-bold mt-1" for="h-contact"
                    >Confirm Password</label
                  >
                  <validation-provider
                    #default="{ errors }"
                    rules="required|password"
                    name="Confirm"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        autocomplete="new-password"
                        id="h-contact"
                        maxlength="20"
                        v-model="confirm_password"
                        :type="confirmpasswordIcon"
                        name="Confirm Password"
                        placeholder="Confirm Password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="ConfirmIcon"
                          @click="toggleConfirmPassword"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small v-if="passwordMismatch" class="text-danger"
                      >Password and Confirm Password must be same.</small
                    >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <label for="input">Select Membership Plan</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Select Plan"
                >
                  <v-select
                    placeholder="Select Your Plans"
                    label="name"
                    :searchable="false"
                    :options="getOptions('plan')"
                    v-model="plans"
                  ></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <!-- @click="Congo" -->
              <b-col md="12" class="d-flex justify-content-center mt-2">
                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  :disabled='saving'
                  @click.prevent="handleFormSubmission()"
                  style="font-family: Public Sans"
                >
                  Submit
                </b-button>
                <b-button
                  type="reset"
                  @click="CloseModal"
                  variant="primary"
                  style="font-family: Public Sans"
                >
                  Cancel
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-modal>
      <b-modal
      v-model="customerView"
      no-close-on-backdrop
      hide-footer
      centered
      size="md">
      <customer-view :selectedId="selectedId"/>
      </b-modal>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BFormGroup,
  BCardHeader,
  BFormInput,
  BInputGroup,
  BPagination,
  BCardTitle,
  BRow,
  BForm,
  BInputGroupAppend,
  BCardBody,
  BButton,
  BModal,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BImg,
  BBadge,
  BSpinner,
  BCol,
} from "bootstrap-vue";
import axios from "@/component/axios";
import dashboard1 from "../../../../assets/images/svg/dasboard1.svg";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import moment from "moment";
import customerView from './CustomerView.vue'
export default {
  components: {
    BCard,
    BPagination,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BCardHeader,
    BBadge,
    BCardTitle,
    BCol,
    moment,
    BRow,
    BForm,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BCardBody,
    BImg,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BModal,
    BInputGroupAppend,
    customerView
  },
  data() {
    return {
      dashboard1,
      isDataLoading: false,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      CustomerData: [],
      selectPlan: false,
      plans: "",
      originalDate:moment().format("DD/MM/YYYY"),
      selectedID: 0,
      password: "",
      passwordIcon: "password",
      // UserID: "",
      confirm_password: "",
      confirmpasswordIcon: "password",
      passwordMismatch: false,
      RejectModal: false,
      reject: "",
      pagination: "",
      paginationStart: 0,
      paginationEnd: 0,
      paginationValue: 1,
      paginationTotal: 0,
      per_page: 10,
      customerView:false,
      selectedId:null,
      saving:false
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordIcon === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    ConfirmIcon() {
      return this.confirmpasswordIcon === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  watch: {
    password() {
      this.validatePasswordMatch();
    },
    confirm_password() {
      this.validatePasswordMatch();
    },
  },
  mounted() {
    this.getRecentClient();
  },
  methods: {
    handleView(id){
      this.selectedId = id
      this.customerView = !this.customerView 
    },
    changePagination() {
      this.getRecentClient();
    },
    validatePasswordMatch() {
      if (this.password !== this.confirm_password) {
        this.passwordMismatch = true;
      } else {
        this.passwordMismatch = false;
      }
    },
    toggleConfirmPassword() {
      this.confirmpasswordIcon =
        this.confirmpasswordIcon === "password" ? "text" : "password";
    },
    togglePassword() {
      this.passwordIcon =
        this.passwordIcon === "password" ? "text" : "password";
    },

    handleFormSubmission() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          this.ApproveClient("Approved", this.selectedID);
        }
      });
    },
    handleRejectForm() {
      this.$refs.simple.validate().then(async (success) => {
        if (success) {
          this.RejectClient("Rejected", this.selectedID);
        }
      });
    },
    RejectClient(e, id) {
      this.saving=true
      const customerData = {
        approve: e,
        id: id,
        rejectreason: this.reject,
      };
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}dashboard/request-approval`,
        data: JSON.stringify(customerData),
      };
      axios(requestOptions)
        .then((response) => {
          let messageToShow = "";

          if (e === "Rejected") {
            messageToShow = "Client Rejected successfully";
          } else {
            messageToShow =
              response.data.message || "Client approved Successfully";
          }
          this.$swal({
            title: "Submitted",
            text: messageToShow,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.selectPlan = false;
          this.RejectModal = false;
          this.getRecentClient();
          this.$forceUpdate();
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.$swal({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
              timer: 5000,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          } else {
            this.$swal({
              title: "Error!",
              text: `${error}`,
              icon: "error",
              timer: 5000,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
        })
        .finally(()=>{
          this.saving=false
        })
    },
    ApproveClient(e, id) {

      let endDate;

      switch (this.plans.id) {
          case 1:
            endDate = moment(this.originalDate, "DD/MM/YYYY")
              .add(1, "month")
              .format("YYYY/MM/DD");
            break;
          case 2:
            endDate = moment(this.originalDate, "DD/MM/YYYY")
              .add(3, "months")
              .format("YYYY/MM/DD");
            break;
          case 3:
            endDate = moment(this.originalDate, "DD/MM/YYYY")
              .add(6, "months")
              .format("YYYY/MM/DD");
            break;
          case 4:
            endDate = moment(this.originalDate, "DD/MM/YYYY")
              .add(9, "months")
              .format("YYYY/MM/DD");
            break;
          case 5:
            endDate = moment(this.originalDate, "DD/MM/YYYY")
              .add(12, "months")
              .format("YYYY/MM/DD");
            break;
          default:
            // Handle other cases or set a default behavior
            break;
        }
        this.saving=true

      const customerData = {
        approve: e,
        id: id,
        plan_id: this.plans.id,
        password: this.password,
        // username: this.UserID,
        confirm_password: this.confirm_password,
        startdate: moment().format("YYYY-MM-DD"),
        enddate: moment().add(3, "months").format("YYYY-MM-DD"),
      };
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}dashboard/request-approval`,
        data: JSON.stringify(customerData),
      };
      axios(requestOptions)
        .then((response) => {
          let messageToShow = "";

          if (e === "Rejected") {
            messageToShow = "Client Rejected successfully";
          } else {
            messageToShow =
              response.data.message || "Client approved Successfully";
          }
          this.$swal({
            title: "Submitted",
            text: messageToShow,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.selectPlan = false;
          this.getRecentClient();
          this.$forceUpdate();
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.$swal({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
              timer: 5000,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          } else {
            this.$swal({
              title: "Error!",
              text: `${error}`,
              icon: "error",
              timer: 5000,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
        })
        .finally(()=>{
            this.saving=false

        })
    },
    async getRecentClient() {
      this.isDataLoading = true;
      let params = `?page=${this.paginationValue}&per_page=${this.per_page}${
        this.search ? "&search=" + this.search : ""
      }${
        this.search && this.searchTerm ? "&type=" + this.searchTerm.value : ""
      }`;
      await axios({
        method: "GET",
        url: `${this.baseApi}dashboard/get-recent-client${params}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.CustomerData = json?.data.data.customers.data;
          this.pagination = json?.data.data.customers;
          this.paginationTotal = json?.data.data.customers.total;
          this.paginationStart = json?.data.data.customers.from;
          this.paginationEnd = json?.data.data.customers.to;
          this.isDataLoading = false;
        })
        .catch((error) => console.log(error, "error"));
      this.isDataLoading = false;
    },
    openModel(id) {
      // this.UserID = "";
      this.plans = ""
      this.password = "";
      this.confirm_password = "";
      this.selectPlan = true;
      this.selectedID = id;
    },
    openRejectModal(id) {
      this.RejectModal = true;
      this.selectedID = id;
      this.reject = ""
    },
    CloseModal() {
      this.selectPlan = false;
      this.RejectModal = false;
    },
  },
};
</script>
