<template>
  <b-card no-body>
    <b-card-header class="w-100">
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1"> Latest updates </b-card-title>
        <b-card-sub-title>Fitness Network</b-card-sub-title>
      </div>
      <!--/ title and subtitle -->
      <div class="d-flex justify-content-between flex-wrap" style="width: 19%">
        <div class="d-flex justify-content-center align-items-center">
          <div class="circle" style="background-color: #a4f8cd; margin-right: 3px" />
          <div class="text-muted">Clients:</div>
          <strong class="ml-1"> {{ allCustomer }}</strong>
        </div>
        <div class="d-flex justify-content-center align-items-center">
          <div class="circle" style="background-color: #2bdac7; margin-right: 3px" />
          <div class="text-muted">Trainers:</div>
          <strong class="ml-1"> {{ allTrainer }}</strong>
        </div>
      </div>
      <!-- <feather-icon icon="CalendarIcon" size="16" /> -->
      <!-- <v-select style="width:150px !important" :clearable="false"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :searchable="false"
            v-model="rangePicker" placeholder="Select" class="border-0 per-page-selector d-inline-block mx-50"
            label="name" :options="searchOptions" @change="handleForm($event)" /> -->
      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <flat-pickr
          style="width: 200px; background-color: #efefef"
          v-model="graphData"
          :config="{ mode: 'range', dateFormat: 'd-m-Y' }"
          @input="handleDate($event)"
          class="form-control p-0 m-0 flat-picker text-center border-0 shadow-none"
          placeholder="DD/MM/YYYY"
        />
      </div>
      <!-- datepicker -->
    </b-card-header>

    <b-card-body>
      <div
        v-if="loadGraph"
        style="height: 200px"
        class="d-flex justify-content-center align-items-center"
      >
        <b-spinner label="Loading..."></b-spinner>
      </div>
      <vue-apex-charts
        v-else
        type="area"
        height="400"
        :options="lineAreaChartSpline.chartOptions"
        :series="lineAreaChartSpline.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BCol,
  BSpinner,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";
import axios from "@/component/axios";
import moment from "moment";
import _ from "lodash";
import vSelect from "vue-select";

const chartColors = {
  column: {
    series1: "#826af9",
    series2: "#d2b0ff",
    bg: "#f8d3ff",
  },
  success: {
    shade_100: "#7eefc7",
    shade_200: "#06774f",
  },
  donut: {
    series1: "#ffe700",
    series2: "#00d4bd",
    series3: "#826bf8",
    series4: "#2b9bf4",
    series5: "#FFA1A1",
  },
  area: {
    series3: "#a4f8cd",
    series2: "#60f2ca",
    series1: "#2bdac7",
  },
};

export default {
  components: {
    BCard,
    moment,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BCol,
    vSelect,
    BCardTitle,
    BCardSubTitle,
    flatPickr,
    BSpinner,
  },
  data() {
    return {
      rangePicker: [],
      allTrainer: 0,
      allCustomer: 0,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      searchOptions: [{ name: "Today" }, { name: "Month" }, { name: "Year" }],
      graphData: "",
      lineAreaChartSpline: {
        series: [
          {
            name: "Clients",
            data: [],
          },
          {
            name: "Trainers",
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: false,
            curve: "straight",
          },
          legend: {
            show: false,
            position: "top",
            horizontalAlign: "center",
            fontSize: "14px",
            fontFamily: "Montserrat",
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            // opposite: isRtl
          },
          fill: {
            opacity: 1,
            type: "solid",
          },
          tooltip: {
            shared: false,
          },
          colors: [chartColors.area.series3, chartColors.area.series1],
        },
      },
      loadGraph: false,
    };
  },
  // watch:{
  // '$store.state.app.customers':{
  //   handler:'getClientData',
  //   immediate:true
  // },
  // '$store.state.app.trainers':{
  //   handler:'getTrainerData',
  //   immediate:true
  // },
  // },
  mounted() {
    this.graphData = `${moment()
      .subtract(10, "days")
      .format("DD-MM-YYYY")} to ${moment().format("DD-MM-YYYY")}`;
    // this.getTrainerData();
    // this.getClientData();
    this.getUserCount();
  },
  methods: {
    handleDate: _.debounce(function (e) {
      if (e.split(" to ").length == 2) {
        this.handleForm();
      }
    }, 500),
    handleChange(e) {
      let from = e.split("to")[0];
      let to = e.split("to")[1];
    },
    async getClientData() {
      this.allCustomer = this.$store.state.app.customers;
    },
    async getUserCount() {
      (this.isSideDataLoading = true),
        await axios({
          method: "GET",
          url: `${this.baseApi}trainer/get-user-count`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
        })
          .then((json) => {
            json.data.data.map((item) => {
              if (item.type == "trainer") {
                this.allTrainer = item.count;
              } else if (item.type == "customer") {
                this.allCustomer = item.count;
              }
            });
          })
          .catch((error) => console.log(error, "error"));
          this.isSideDataLoading = false;
    },

    handleForm() {
      this.loadGraph = true;
      let array = this.graphData.split(" to ");
      const customerData = {
        start_date: array[0],
        end_date: array[1],
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}dashboard/get-graph-data`,
        data: JSON.stringify(customerData),
      };
      axios(requestOptions)
        .then((json) => {
         if (array[0] && array[1] && moment(array[0],'DD-MM-YYYY').year() != moment(array[1],'DD-MM-YYYY').year()) {
            let years = json.data.data.map((item) => moment(item.date).format('YYYY'));
            years = [...new Set(years)]
            let customer = {}
            let trainer = {}
            json.data.data.map((item) => {
              customer[moment(item.date).format('YYYY')] = customer[moment(item.date).format('YYYY')] ? customer[moment(item.date).format('YYYY')] +item.customers: item.customers
              trainer[moment(item.date).format('YYYY')] = trainer[moment(item.date).format('YYYY')] ? trainer[moment(item.date).format('YYYY')] +item.trainers: item.trainers
            });
            this.lineAreaChartSpline.series[0].data = years.map((item)=> customer[item]);
            this.lineAreaChartSpline.series[1].data = years.map((item)=> trainer[item]);
            this.lineAreaChartSpline.chartOptions.xaxis.categories = years;
          }
          else if (array[0] && array[1] && moment(array[0],'DD-MM-YYYY').month() != moment(array[1],'DD-MM-YYYY').month()) {
            let months = json.data.data.map((item) => moment(item.date).format('MMMM'));
            months = [...new Set(months)]
            console.log(months);
              let customer = {}
              let trainer = {}
              json.data.data.map((item) => {
                customer[moment(item.date).format('MMMM')] = customer[moment(item.date).format('MMMM')] ? customer[moment(item.date).format('MMMM')] +item.customers: item.customers
                trainer[moment(item.date).format('MMMM')] = trainer[moment(item.date).format('MMMM')] ? trainer[moment(item.date).format('MMMM')] +item.trainers: item.trainers
              });
              this.lineAreaChartSpline.series[0].data = months.map((item)=> customer[item]);
              this.lineAreaChartSpline.series[1].data = months.map((item)=> trainer[item]);
              this.lineAreaChartSpline.chartOptions.xaxis.categories = months;
          }
          else {
            this.lineAreaChartSpline.series[0].data = json.data.data.map(
              (item) => item.customers
            );
            this.lineAreaChartSpline.series[1].data = json.data.data.map(
              (item) => item.trainers
            );
            this.lineAreaChartSpline.chartOptions.xaxis.categories = json.data.data.map(
              (item) => moment(item.date).format("DD/MM")
            );
          }


          this.$forceUpdate();
          // setTimeout(this.handleForm, 5 * 60 * 1000);
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loadGraph = false;
        });
    },
  },
};
</script>
<style>
.circle {
  width: 10px;
  /* Adjust the width and height to control the size of the circle */
  height: 10px;
  border-radius: 50%;
  /* Make the element round by setting border-radius to 50% */
}
</style>
