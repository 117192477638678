<template>
  <b-row class="match-height">
    <b-col cols="12">
      <p class="mb-2">
        <!-- <span>A Vue.js component for ApexCharts. Read full documnetation</span> -->
        <!-- <b-link target="_blank" href="https://apexcharts.com/docs/installation/">
                    here
                </b-link> -->
      </p>
    </b-col>
    <b-col cols="12">
      <apex-line-area-chart />
    </b-col>
    <b-col md="6" lg="4" sm="12">
      <b-card no-body>
        <b-card-body
          style="box-shadow: -18px 20px 12px -5px rgba(0, 0, 0, 0.1)"
        >
          <div class="d-flex">
            <div
              class="d-flex w-100 flex-column justify-content-between dashboard-card"
            >
              <b-button variant="primary" @click="openModal()" size="md"
                >Add Clients</b-button
              >
              <b-button variant="primary" @click="openTrainerModal()" size="md"
                >Add Trainers</b-button
              >
              <b-button variant="primary" @click="openRenewalModal()" size="md"
                >Plan Renewal Request<b-badge
                  pill
                  style="
                    position: relative;
                    left: 12px;
                    color: #7367f0;
                    font-weight: 900;
                  "
                  variant="light"
                ></b-badge>
              </b-button>
              <b-modal
                hide-footer
                no-close-on-backdrop
                v-model="AddNewUser"
                centered
                size="lg"
              >
                <validation-observer ref="simpleRules">
                  <b-form ref="myForm">
                    <b-row>
                      <b-col
                        md="12"
                        class="font-weight-bolder mt-1 d-flex align-items-center flex-column"
                      >
                        <h3
                          style="
                            font-size: 26px;
                            font-family: 'Public Sans', sans-serif;
                          "
                        >
                          Add New Client
                        </h3>
                        <p>
                          Updating user details will receive a privacy audit.
                        </p>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label
                            class="font-weight-bold mt-1"
                            for="h-first-name"
                            >Full Name</label
                          >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="Full Name"
                          >
                            <b-form-input
                              id="h-first-name"
                              v-model="fullname"
                              placeholder="First Name"
                              maxlength="50"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label class="font-weight-bold mt-1" for="h-gender"
                            >Gender</label
                          >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="Gender"
                          >
                            <v-select
                              id="h-gender"
                              v-model="gender"
                              label="name"
                              :options="getOptions('gender')"
                              placeholder="Select gender"
                              :searchable="false"
                              :clearable="false"
                            ></v-select>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label class="font-weight-bold mt-1">Goal</label>
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="Goal"
                          >
                            <v-select
                              v-model="goal"
                              :searchable="false"
                              label="name"
                              :options="getOptions('goal')"
                              @input="handleWeeklyGoal"

                              placeholder="Select your goal"
                            ></v-select>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6" :class="goal && goal.name!= 'Maintain Weight'?'':'d-none'">
                        <b-form-group>
                          <label class="font-weight-bolder mt-1" for="h-goal">Weekly Goal</label>
                            <v-select
                              id="h-goal"
                              v-model="weekly_goal"
                              label="name"

                              :options="goal && goal.name=='Gain Weight'? getOptions('gainWeightData') :getOptions('loseWeightData') "
                              placeholder="Select your weekly goal"
                            ></v-select>
                        </b-form-group>
                      </b-col>
                      <!-- <b-col cols="6">
                        <b-form-group>
                          <label
                            class="font-weight-bold mt-1"
                            for="h-first-name"
                            >Create User ID</label
                          >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="UserID"
                          >
                            <b-form-input
                              type="text"
                              autocomplete="new-text"
                              v-model="UserID"
                              maxlength="20"
                              placeholder="UserID"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col> -->

                      <b-col cols="6">
                        <b-form-group>
                          <label class="font-weight-bold mt-1" for="h-first-name"
                            >Birth Date</label
                          >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="Date of Birth"
                          >
                          <!-- <flat-pickr
                              v-model="dob"
                              style="background-color: transparent"
                              class="form-control "
                              placeholder="DD/MM/YYYY"
                              :config='{
                                dateFormat: "m/d/Y",
                                maxDate: "today"
                              }'
                            /> -->
<!-- 
                             <datepicker v-model="dob" 
                             inputClass="form-control bg-transparent" format="dd/MM/yyyy"></datepicker> -->
                             <datepicker 
                                v-model="dob" 
                                inputClass="form-control bg-transparent"
                                format="dd/MM/yyyy"
                                :disabled-dates="disabledDates"
                              ></datepicker>

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label class="font-weight-bold mt-1">Height</label>
                          <validation-provider
                            #default="{ errors }"
                            rules="integer"
                            name="Height"
                          >
                            <b-form-input
                              v-model="height"
                              placeholder="158 Cm"
                              :maxlength="3"
                            />
                              <!-- @input="limitLength" -->
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label class="font-weight-bold mt-1" for="h-weight"
                            >Current Weight (Kg)</label
                          >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="Current Weight"
                          >
                            <b-form-input
                              id="h-weight"
                              v-model="weight"
                              type='number'
                              placeholder="60"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label class="font-weight-bold mt-1" for="h-weight"
                            >Goal Weight (Kg)</label
                          >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="Goal Weight"
                          >
                            <b-form-input
                              id="h-weight"
                              v-model="goal_weight"
                              type='number'
                              placeholder="60"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label class="font-weight-bold mt-1" for="h-email"
                            >Email</label
                          >
                          <validation-provider
                            #default="{ errors }"
                            rules="required|email"
                            name="Email"
                          >
                            <b-form-input
                              id="h-email"
                              v-model="email"
                              placeholder="Email"
                              type="email"
                              maxlength="50"
                            />
                            <small
                              v-if="con_email!== email"
                              class="text-danger"
                              >Email and Confirm email must be
                              same.</small
                            >
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label class="font-weight-bold mt-1" for="h-email"
                            >Confirm Email</label
                          >
                          <validation-provider
                            #default="{ errors }"
                            rules="required|email"
                            name="Confirm Email"
                          >
                            <b-form-input
                              id="h-email"
                              v-model="con_email"
                              placeholder="Email"
                              type="email"
                              maxlength="50"
                            />
                            <small
                              v-if="con_email!== email"
                              class="text-danger"
                              >Email and Confirm email must be
                              same.</small
                            >
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label class="font-weight-bold mt-1" for="h-contact"
                            >Contact</label
                          >
                          <validation-provider
                            #default="{ errors }"
                            rules="integer"
                            name="Contact"
                          >
                            <b-form-input
                              id="h-contact"
                              maxlength="15"
                              v-model="contact"
                              placeholder="Contact No."
                            />
                            <small
                              v-if="con_contact!= contact"
                              class="text-danger"
                              >Contact no. and Confirm contact no. must be
                              same.</small
                            >
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label class="font-weight-bold mt-1" for="h-contact"
                            >Confirm Contact</label
                          >
                          <validation-provider
                            #default="{ errors }"
                            rules="integer"
                            name="Confirm Contact"
                          >
                            <b-form-input
                              id="h-contact"
                              maxlength="15"
                              v-model="con_contact"
                              placeholder="Contact No."
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            <small
                              v-if="con_contact!= contact"
                              class="text-danger"
                              >Contact no. and Confirm contact no. must be
                              same.</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label class="font-weight-bold mt-1">Password</label>
                          <validation-provider
                            #default="{ errors }"
                            rules="required|password"
                            name="password"
                          >
                            <b-input-group class="input-group-merge">
                              <b-form-input
                                autocomplete="new-password"
                                v-model="password"
                                :type="passwordIcon"
                                name="Password"
                                placeholder="Password"
                                maxlength="50"
                              />
                              <b-input-group-append is-text>
                                <feather-icon
                                  class="cursor-pointer"
                                  :icon="passwordToggleIcon"
                                  @click="togglePassword"
                                />
                              </b-input-group-append>
                            </b-input-group>
                            <small
                              v-if="passwordMismatch == true"
                              class="text-danger"
                              >Password and Confirm Password must be
                              same.</small
                            >
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label class="fw-bold mt-1">Confirm Password</label>
                          <validation-provider
                            #default="{ errors }"
                            rules="required|password"
                            name="Confirm"
                          >
                            <b-input-group class="input-group-merge">
                              <b-form-input
                                autocomplete="new-password"
                                v-model="confirm_password"
                                :type="confirmpasswordIcon"
                                name="Confirm Password"
                                placeholder="Confirm Password"
                                maxlength="50"
                              />
                              <b-input-group-append is-text>
                                <feather-icon
                                  class="cursor-pointer"
                                  :icon="ConfirmIcon"
                                  @click="toggleConfirmPassword"
                                />
                              </b-input-group-append>
                            </b-input-group>
                            <small v-if="passwordMismatch" class="text-danger"
                              >Password and Confirm Password must be
                              same.</small
                            >
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label class="mt-1 font-weight-bold" for="h-languag"
                            >Language</label
                          >
                          <validation-provider #default="{ errors }">
                            <b-form-input
                              id="h-language"
                              :value="language"
                              disabled
                              label="name"
                              placeholder="English"
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label class="font-weight-bold mt-1" for="h-country"
                            >Country</label
                          >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="Country"
                          >
                            <v-select
                              v-model="country"
                              label="name"
                              :options="getOptions('country')"
                              placeholder="Select Country"
                            ></v-select>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group>
                          <label class="font-weight-bold mt-1"
                            >Medical Injuries</label
                          >
                            <b-form-textarea
                              v-model="injuries"
                              placeholder="Medical Injuries"
                            />
                        </b-form-group>
                      </b-col> <b-col cols="12">
                        <b-form-group>
                          <label class="font-weight-bold mt-1"
                            >Perpose of Workout</label
                          >
                            <b-form-textarea
                              v-model="purpose_of_workout"
                              placeholder="Perpose Of Workout"
                            />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        class="d-flex flex-column align-items-center"
                      >
                        <h3
                          class="font-weight-bolder mt-1"
                          style="
                            font-size: 26px;
                            font-family: 'Public Sans', sans-serif;
                          "
                        >
                          Membership Details
                        </h3>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group>
                          <label class="font-weight-bolder mt-1" for="h-plan"
                            >Select Plans</label
                          >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="Plan"
                          >
                            <v-select
                              id="h-plan"
                              v-model="plans"
                              label="name"
                              :options="getOptions('plan')"
                              placeholder="Select Your Plan"
                            ></v-select>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- submit and reset -->
                      <b-col md="12" class="d-flex justify-content-center mt-0">
                        <b-button
                          @click.prevent="handleNewUserPage()"
                          type="submit"
                          variant="primary"
                          class="mr-1 mt-1 font-weight-bold"
                          style="
                            font-size: 15px;
                            font-family: 'Public Sans', sans-serif;
                          "
                        >
                          Submit
                        </b-button>
                        <b-button
                          type="reset"
                          @click="close"
                          variant="primary"
                          class="font-weight-bold mt-1"
                          style="
                            font-size: 15px;
                            font-family: 'Public Sans', sans-serif;
                          "
                        >
                          Cancel
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </validation-observer>
              </b-modal>
              
              <b-modal
                hide-footer
                v-model="assignTrainer"
                no-close-on-backdrop
                centered
                size="md"
              >
                <validation-observer ref="Rules">
                  <b-row class="p-2">
                    <b-col
                      md="12"
                      class="d-flex align-items-center flex-column"
                    >
                      <h2
                        style="font-size:26px font-weight:600;font-family: 'Public Sans', sans-serif;"
                      >
                        Clients assigned to trainers
                      </h2>
                      <p
                        style="font-size:15px font-weight:400;font-family: 'Public Sans', sans-serif;"
                      >
                        "Assign to trainers with our gym management software."
                      </p>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group>
                        <label
                          style="font-weight: 500; margin-top: 10px"
                          for="h-trainer"
                          >Select Trainer</label
                        >
                        <b-form-input
                          id="h-trainer"
                          @input="handleSearch"
                          v-model="newSearch"
                          placeholder="Name"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md=12 
                        v-if="isLoadingTrainer"
                    >
                     <div
                        style="height: 200px"
                        class="d-flex justify-content-center align-items-center"
                      >
                        <b-spinner label="Loading..."></b-spinner>
                      </div>
                    </b-col>
                    <b-col
                      md="12"
                      v-else
                      class="mt-2 trainerList">
                      <div class="w-100 p-1"
                      v-for="(item, index) in allTrainer"
                      :key="index"
                      >
                        <div class="d-flex w-100 align-items-center">
                          <div class="mr-1">
                            <b-avatar size="32" :src="item.profilephoto" />
                          </div>
                          <div class="w-100">
                            <h5
                              class="m-0 font-weight-bolder"
                              style="
                                font-size: 15px;
                                font-family: 'Public Sans', sans-serif;
                              "
                            >
                              {{ item.f_name }}
                            </h5>
                            <small>{{
                              setspecialization(item.all_specialization)
                            }}</small>
                          </div>
                          <div
                            class="d-flex w-100 align-items-center justify-content-end"
                          >
                            <b-button
                              variant="primary"
                              :disabled='submiting'
                              @click="handleSubmitForm(item.id)"
                            >
                              <span>Select</span>
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </validation-observer>
                <div class='d-flex justify-content-end'>
                  <b-button @click='handleBackFromTrainer' variant="primary">Back</b-button>
                </div>
              </b-modal>

              <b-modal
                hide-footer
                no-close-on-backdrop
                @close="CloseTrainer"
                v-model="TrainerAddNewTrainer"
                centered
                size="lg"
              >
                <validation-observer ref="Rules">
                  <b-form ref="myTrainerForm">
                    <b-row>
                      <b-col
                        md="12"
                        class="d-flex align-items-center flex-column"
                      >
                        <h2
                          class="font-weight-bolder"
                          style="
                            font-size: 26px;
                            font-family: 'Public Sans', sans-serif;
                          "
                        >
                          Add New Trainer
                        </h2>
                        <p
                          style="
                            font-weight: 500;
                            font-size: 15px;
                            font-family: 'Public Sans', sans-serif;
                          "
                        >
                          Updating user details will receive a privacy audit.
                        </p>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label
                            class="font-weight-bold mt-1"
                            for="h-first-name"
                            >Full Name</label
                          >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="Full Name"
                          >
                            <b-form-input
                              v-model="TrainerFullname"
                              placeholder="First Name"
                              maxlength="100"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label class="font-weight-bold mt-1" for="h-gender"
                            >Gender</label
                          >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="Gender"
                          >
                            <v-select
                              v-model="TrainerGender"
                              label="name"
                              :searchable="false"
                              :clearable="false"
                              :options="getOptions('gender')"
                              placeholder="Select gender"
                            ></v-select>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label class="font-weight-bold mt-1" for="h-goal"
                            >Address</label
                          >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="Address"
                          >
                            <b-form-input
                              v-model="TrainerAddress"
                              placeholder="Address"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- <b-col cols="6">
                        <b-form-group>
                          <label
                            class="font-weight-bold mt-1"
                            for="h-first-name"
                            >Create User ID</label
                          >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="UserID"
                          >
                            <b-form-input
                              type="text"
                              v-model="TrainerUserId"
                              maxlength="50"
                              placeholder="UserID"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col> -->
                      <b-col cols="6">
                        <b-form-group>
                          <label class="font-weight-bold mt-1" for="h-first-name"
                            >Birth Date</label
                          >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="Date of Birth"
                          >
                          <!-- <flat-pickr
                              v-model="trainer_dob"
                              style="background-color: transparent"
                              class="form-control "
                              placeholder="DD/MM/YYYY"
                              :config='{
                                dateFormat: "m/d/Y",
                                maxDate: "today"
                              }'
                            /> -->
                          
                             <!-- <datepicker v-model="trainer_dob" 
                             inputClass="form-control bg-transparent"
                              format="dd/MM/yyyy"></datepicker> -->

                              <datepicker 
                                v-model="trainer_dob" 
                                inputClass="form-control bg-transparent"
                                format="dd/MM/yyyy"
                                :disabled-dates="disabledDates"
                              ></datepicker>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label class="font-weight-bold mt-1" for="h-height"
                            >Height</label
                          >
                          <validation-provider
                            #default="{ errors }"
                            rules="integer"
                            name="Height"
                          >
                            <b-form-input
                              v-model="TrainerHeight"
                              placeholder="158 Cm"
                              maxlength="3"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label class="mt-1 font-weight-bold">Weight</label>
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="Weight"
                          >
                            <b-form-input
                              v-model="TrainerWeight"
                              placeholder="60Kg"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label class="font-weight-bold mt-1" for="h-email"
                            >Email</label
                          >
                          <validation-provider
                            #default="{ errors }"
                            rules="required|email"
                            name="Email"
                          >
                            <b-form-input
                              v-model="TrainerEmail"
                              placeholder="Email"
                              type="email"
                              maxlength="50"
                            />
                            <small v-if="con_TrainerEmail!== TrainerEmail" class="text-danger"
                            >Email and Confirm email must be
                            same.</small
                          >
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label class="font-weight-bold mt-1" for="h-email"
                            >Confirm Email</label
                          >
                          <validation-provider
                            #default="{ errors }"
                            rules="required|email"
                            name="Confirm Email"
                          >
                            <b-form-input
                              v-model="con_TrainerEmail"
                              placeholder="Email"
                              type="email"
                              maxlength="50"
                            />
                            <small v-if="con_TrainerEmail!== TrainerEmail" class="text-danger"
                            >Email and Confirm email must be
                            same.</small
                          >
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label class="font-weight-bold mt-1" for="h-contact"
                            >Contact</label
                          >
                          <validation-provider
                            #default="{ errors }"
                            rules="integer"
                            name="Contact"
                          >
                            <b-form-input
                              maxlength="15"
                              v-model="TrainerContact"
                              placeholder="Contact No."
                            />
                            <small v-if="con_TrainerContact!== TrainerContact" class="text-danger"
                            >Contact no. and Confirm contact no. must be
                            same.</small
                          >
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label class="font-weight-bold mt-1" for="h-contact"
                            >Confirm Contact</label
                          >
                          <validation-provider
                            #default="{ errors }"
                            rules="integer"
                            name="Confirm Contact"
                          >
                            <b-form-input
                              maxlength="15"
                              v-model="con_TrainerContact"
                              placeholder="Contact No."
                            />
                            <small v-if="con_TrainerContact!== TrainerContact" class="text-danger"
                              >Contact no. and Confirm contact no. must be
                              same.</small
                            >
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label class="font-weight-bold mt-1" for="h-languag"
                            >Language</label
                          >
                          <validation-provider #default="{ errors }">
                            <b-form-input
                              placeholder="English"
                              label="Language"
                              disabled
                              :value="TrainerLanguage"
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label class="font-weight-bold mt-1" for="h-country"
                            >Country</label
                          >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="Country"
                          >
                            <v-select
                              v-model="TrainerCountry"
                              label="name"
                              :options="getOptions('country')"
                              placeholder="Select Country"
                            ></v-select>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label class="font-weight-bold mt-1">Password</label>
                          <validation-provider
                            #default="{ errors }"
                            rules="required|password"
                            name="password"
                          >
                            <b-input-group class="input-group-merge">
                              <b-form-input
                                autocomplete="new-password"
                                v-model="TrainerPassword"
                                :type="passwordIcon"
                                placeholder="Password"
                                maxlength="50"
                              />
                              <b-input-group-append is-text>
                                <feather-icon
                                  class="cursor-pointer"
                                  :icon="passwordToggleIcon"
                                  @click="togglePassword"
                                />
                              </b-input-group-append>
                            </b-input-group>
                            <small v-if="TrainerPasswordMismatch" class="text-danger"
                              >Password and Confirm Password must be
                              same.</small
                            >
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label class="font-weight-bold mt-1"
                            >Confirm Password</label
                          >
                          <validation-provider
                            #default="{ errors }"
                            rules="required|password"
                            name="Confirm"
                          >
                            <b-input-group
                              autocomplete="new-password"
                              class="input-group-merge"
                            >
                              <b-form-input
                                v-model="TrainerConfirm_password"
                                :type="confirmpasswordIcon"
                                name="Confirm Password"
                                placeholder="Confirm Password"
                              />
                              <b-input-group-append is-text>
                                <feather-icon
                                  class="cursor-pointer"
                                  :icon="ConfirmIcon"
                                  @click="toggleConfirmPassword"
                                />
                              </b-input-group-append>
                            </b-input-group>
                            <small v-if="TrainerPasswordMismatch" class="text-danger"
                              >Password and Confirm Password must be
                              same.</small
                            >
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label class="font-weight-bold mt-1"
                            >Profile Photo</label
                          >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="Profile"
                          >
                            <b-form-file
                              v-model="profileAttach"
                              placeholder="Upload Photo"
                              @input="
                                uploadDoc($event, 'profileAttach', 'trainer')
                              "
                            ></b-form-file>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label class="font-weight-bold mt-1"
                            >ID (Passport, License)</label
                          >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="ID"
                          >
                            <b-form-file
                              placeholder="Upload ID"
                              v-model="IDAttach"
                              @input="uploadDoc($event, 'IDAttach', 'trainer')"
                            ></b-form-file>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label class="font-weight-bold mt-1" for="h-goal"
                            >Personal Trainer Certificate</label
                          >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="Personal Trainer"
                          >
                            <b-form-file
                              v-model="CertiAttach"
                              placeholder="Upload Cerificate"
                              @input="
                                uploadDoc($event, 'CertiAttach', 'trainer')
                              "
                            ></b-form-file>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label class="font-weight-bold mt-1" for="h-goal"
                            >PT Insurance</label
                          >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="PT Insurance"
                          >
                            <b-form-file
                              placeholder="Upload Insurance"
                              v-model="PtInsurance"
                              @input="
                                uploadDoc($event, 'PtInsurance', 'trainer')
                              "
                            ></b-form-file>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- submit and reset -->
                      <b-col md="12" class="d-flex justify-content-center mt-2">
                        <b-button
                          variant="primary"
                          class="mr-1 font-weight-bold"
                          @click.prevent="handleNewPage($event)"
                          style="
                            font-size: 15px;
                            font-family: 'Public Sans', sans-serif;
                          "
                          :disabled="flag"
                        >
                          Continue
                        </b-button>
                        <b-button
                          type="reset"
                          class="font-weight-bold"
                          style="font-size:15px font-family: 'Public Sans', sans-serif;"
                          variant="primary"
                          @click="CloseTrainer"
                        >
                          Cancel
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </validation-observer>
              </b-modal>
              <b-modal
                v-model="SubmitTrainer"
                no-close-on-backdrop
                hide-footer
                centered
                @close="CloseTrainer"
                size="md"
              >
                <validation-observer ref="simpleRules">
                  <b-form ref="submitTrainerForm">
                    <b-row class="p-1">
                      <b-col
                        md="12"
                        class="mb-1 text-center font-weight-bolder"
                      >
                        <h3 style="font-size: 26px">
                          Specialized in Which Area
                        </h3>
                      </b-col>
                      <b-col
                        md="6"
                        v-for="(option, index) in getOptions('specialized')"
                        :key="index"
                        class="mb-1"
                      >
                        <div
                          class="option"
                          :class="{ selected: selectedOptions.indexOf(option.id)!= -1 }"
                          @click="handleOptionClick(option.id)"
                        >
                          {{ option.name }}
                        </div>
                      </b-col>

                      <b-col md="12" class="my-2 text-center">
                        <h3
                          class="font-weight-bolder"
                          style="font-size: 26px; font-family: Public Sans"
                        >
                          Membership Details
                        </h3>
                      </b-col>
                      <b-col md="12">
                        <label for="input">Select Plans</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Select Plan"
                        >
                          <v-select
                            placeholder="Select Your Plans"
                            label="name"
                            :searchable="false"
                            :options="getOptions('plan')"
                            v-model="TrainerPlans"
                          ></v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                      <!-- @click="Congo" -->
                      <b-col md="12" class="d-flex justify-content-center mt-2">
                        <b-button
                          type="submit"
                          variant="primary"
                          class="mr-1"
                          @click.prevent="handleTrainerSubmit($event)"
                          style="font-family: Public Sans"
                        >
                          Submit
                        </b-button>
                        <b-button
                          type="reset"
                          @click="handleBackTrainer"
                          variant="primary"
                          style="font-family: Public Sans"
                        >
                          Back
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </validation-observer>
              </b-modal>
            </div>
            <!-- <div class="w-100 d-lg-flex align-items-center justify-content-center px-5"> -->
            <b-img width="140px" fluid :src="dashboard1" alt="Login V2" />
          </div>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col
      md="6"
      lg="4"
      sm="12"
      v-if="isDataLoading"
      class="d-flex justify-content-center align-items-center"
    >
      <b-spinner label="Loading..."></b-spinner>
    </b-col>
    <b-col md="6" v-else lg="4" sm="12">
      <ecommerce-earnings-chart
        :title="'Total Clients'"
        :lastMonth="NewAddTrainerClient.customer_count"
        :currentMonth="PieChartDataTrainer.customer_count"
      />
      <!--  :lastMonth="" -->
    </b-col>
    <b-col
      md="6"
      lg="4"
      sm="12"
      v-if="isSideDataLoading"
      class="d-flex justify-content-center align-items-center"
    >
      <b-spinner label="Loading..."></b-spinner>
    </b-col>
    <b-col md="6" v-else lg="4" sm="12">
      <ecommerce-earnings-chart
        :title="'Total Trainers'"
        :lastMonth="NewAddTrainerClient.trainer_count"
        :currentMonth="PieChartDataTrainer.trainer_count"
      />
      <!-- :allTrainerTotal="" -->
    </b-col>
    <b-col md="12" lg="6" sm="12">
      <ecommerce-transactions />
    </b-col>
    <b-col md="12" lg="6" sm="12">
      <trainer-grid-component />
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow,
  BCol,
  BLink,
  BImg,
  BButton,
  BAvatar,
  BBadge,
  BCard,
  BCardHeader,
  BSpinner,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormInput,
  BFormFile,
  BFormGroup,
  BFormSelect,
  BForm,
  BInputGroup,
  BInputGroupAppend,
  BFormTextarea
} from "bootstrap-vue";
import dashboard1 from "../../../assets/images/svg/dasboard1.svg";
import ApexLineAreaChart from "./components/DashboardGraph.vue";
import EcommerceEarningsChart from "./components/EcommerceEarningsChart.vue";
import EcommerceTransactions from "./components/EcommerceTransactions.vue";
import TrainerGridComponent from "./components/TrainerGridComponent.vue";
import vSelect from "vue-select";
import axios from "@/component/axios";
import moment from "moment";
import _ from "lodash";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import Datepicker from 'vuejs-datepicker';

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  components: {
    vSelect,
    moment,
    BFormFile,
    BSpinner,
    BBadge,
    BAvatar,
    axios,
    ValidationProvider,
    ValidationObserver,
    required,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BForm,
    BInputGroup,
    BInputGroupAppend,
    BRow,
    BCol,
    BLink,
    BImg,
    EcommerceEarningsChart,
    TrainerGridComponent,
    EcommerceTransactions,
    BButton,
    ApexLineAreaChart,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    flatPickr,
    Datepicker,
    BFormTextarea
  },
  data() {
    return {
      newTerm: { name: "Trainer", value: "trainer" },
      newSearch: "",
      PieChartDataTrainer: {},
      PieChartDataClient: {},
      NewAddTrainerClient: {},
      isSideDataLoading: false,
      dashboard1,
      fullname: "",
      height: "",
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      originalDate: moment().format("DD/MM/YYYY"),
      weight: "",
      // UserID: "",
      email: "",
      con_email:'',
      con_contact:'',
      contact: "",
      pincode: "",
      isDataLoading: false,
      language: "English",
      langOption: [],
      password: "",
      allCustomer: [],
      confirm_password: "",
      passwordMismatch: false,
      AddNewUser: false,
      gender: "",
      goal: "",
      goalOption: [],
      passwordIcon: "password",
      confirmpasswordIcon: "password",
      genderOption: [],
      country: "",
      weekly_goal:'',
      contryOption: [],
      plans: "",
      plansOption: [],
      id: 0,
      SubmitTrainer: false,
      TrainerAddNewTrainer: false,
      TrainerFullname: "",
      TrainerPasswordMismatch: false,
      TrainerPassword: "",
      selectedOptions: [],
      TrainerConfirm_password: "",
      TrainerGender: "",
      TrainerAddress: "",
      TrainerHeight: "",
      TrainerWeight: "",
      TrainerEmail: "",
      con_TrainerEmail:'',
      TrainerContact: "",
      con_TrainerContact: "",
      TrainerLanguage: "English",
      TrainerCountry: [],
      profileAttach: "",
      purpose_of_workout:'',
      injuries:'',
      IDAttach: "",
      CertiAttach: "",
      PtInsurance: "",
      TrainerInsurance: "",
      TrainerWeightloss: "",
      TrainerCondition: "",
      TrainerStrenth: "",
      allTrainer: [],
      assignTrainer: false,
      TrainerPlans: "",
      // TrainerUserId: "",
      TrainerPlansOption: [],
      TrainerGenderOption: [],
      TrainerContryOption: [],
      specializedOption: [],
      TrainerStaticUsers: [],
      paginationStart: 0,
      paginationEnd: 0,
      paginationValue: 1,
      paginationTotal: 0,
      per_page: 10,
      isLoadingTrainer: false,
      flag: false,
      storeTainer:[],
      dob:'',
      trainer_dob:'',
      disabledDates:{
        from: '',
      },
      submiting:false
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordIcon === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    ConfirmIcon() {
      return this.confirmpasswordIcon === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    setspecialization(){
      return(data)=>{
        if(data){

          let arr = data.map((item)=>{
            return item.specialization ? item.specialization.name :''
          })
          
          return arr.filter(item=> item ).join(', ')
        }
      return ''
      }
    }
  },
  mounted() {
    this.getGraphData();
    // this.getClientData();
    this.getallTrainer();
    let year = new Date().getFullYear() -10
    this.disabledDates.from =new Date(new Date().setFullYear(year));
  },
  watch: {
    
    password() {
      this.validatePasswordMatch();
    },
    confirm_password() {
      this.validatePasswordMatch();
    },
    TrainerPassword(){
    
      this.validatePasswordMatchTrainer();
    },
    TrainerConfirm_password(){
      this.validatePasswordMatchTrainer();

    },
    contact(newHeight) {
      // Enforce a maximum length of 10 for the numeric value
      if (newHeight !== null && newHeight.toString().length > 10) {
        this.contact = parseInt(newHeight.toString().slice(0, 10), 10);
      }
    },
    // '$store.state.app.trainers':{
    //   handler:'getallTrainer',
    //   immediate:true
    // },
  },
  methods: {

    handleWeeklyGoal(){
      this.weekly_goal =''
    },
    handleBackFromTrainer(){
      this.AddNewUser=true;
      this.assignTrainer=false
    },
    // limitLength(e) {
    //   let last = this.height.charAt(this.height.length - 1);
    //   console.log(isNaN(last));
    //   this.height = isNaN(last) ? this.height.replace(last, "") : this.height;
    // },
    validatePasswordMatch() {
      if (this.password !== this.confirm_password) {
        this.passwordMismatch = true;
      } else {
        this.passwordMismatch = false;
      }
    },
    validatePasswordMatchTrainer() {
      if (this.TrainerPassword !== this.TrainerConfirm_password) {
        this.TrainerPasswordMismatch = true;
      } else {
        this.TrainerPasswordMismatch = false;
      }
    },
    handleSearch: _.debounce(function () {
      this.getallTrainer();
    }, 1000),

    // async getClientData() {
    //   this.isDataLoading = true;
    //   await axios({
    //     method: "GET",
    //     url: `${this.baseApi}customer/all`,
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${this.accessToken}`,
    //     },
    //   }).then((json) => {
    //     this.allCustomer = json?.data.data.results;
    //   }).catch((err)=>{
    //     console.log(err,"Error")
    //   }).finally(()=>{
    //     this.isDataLoading = false;
    //   })
    // },

    async getallTrainer() {
      this.isLoadingTrainer = true;
       let params ={}
      if(this.newSearch){
        params.search= this.newSearch;
        params.type=this.newTerm.value
      }
      await axios({
        method: "GET",
        url: `${this.baseApi}trainer/get-all-trainer`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        params:params
      })
        .then((json) => {
          this.allTrainer = json?.data.data;
          this.totalTairner = this.allTrainer.length;
        })
        .catch((error) => console.log(error, "error"))
        .finally(()=>{
          this.isLoadingTrainer = false;

        });

      // this.storeTainer = this.$store.state.app.trainers;


      //     if(this.newSearch){
      //       this.allTrainer = this.storeTainer.filter((item)=>{
      //         let regex = new RegExp(this.newSearch, 'i');
      //         return regex.test(item.f_name)
      //       })
      //     }
      //     else{
      //       this.allTrainer = this.storeTainer.slice()
      //     }
    },
    async getGraphData() {
      this.isDataLoading = true;
      this.isSideDataLoading = true;
      await axios({
        method: "GET",
        url: `${this.baseApi}dashboard/get-data`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((response) => {
          this.PieChartDataTrainer = response?.data.data.current_month;
          this.NewAddTrainerClient = response?.data.data.last_month;
          this.isDataLoading = false;
          this.isSideDataLoading = false;
          this.$forceUpdate();
        })
        .catch((error) => console.log(error, "error"));
      this.isDataLoading = false;
      this.isSideDataLoading = false;
    },

    toggleConfirmPassword() {
      this.confirmpasswordIcon =
        this.confirmpasswordIcon === "password" ? "text" : "password";
    },
    togglePassword() {
      this.passwordIcon =
        this.passwordIcon === "password" ? "text" : "password";
    },
    handleOptionClick(id) {
      let index = this.selectedOptions.indexOf(id);
      if (index == -1) {
        this.selectedOptions.push(id);
      } else {
        this.selectedOptions.splice(index, 1);
      }
    },
    async uploadDoc(event, variable, name) {
      this.flag = true;
      if (event && variable) {
        const formData = new FormData();
        formData.append("url", event);
        formData.append("type", "image");
        await axios({
          method: "POST",
          url: `${this.baseApi}attachments/uploadattachment`,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.accessToken}`,
          },
          data: formData,
        }).then(async (response) => {
          //   console.log(response.data.data.url)
          //   let data = {
          //     url: response.data.data.url,
          //     admin_id: JSON.parse(localStorage.getItem('userData')).id,
          //     status: 1
          //   }
          this[variable] = response.data.data.url;
          this.flag = false;
        });
      }
    },
    handleNewUserPage() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success  && !this.passwordMismatch && this.email===this.con_email && this.contact===this.con_contact) {
          if (this.AddNewUser) {
            this.AddNewUser = false;
          }
          this.assignTrainer = true;
        }else{
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Please fill in all fields",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    async handleSubmitForm(id) {
      this.$refs.Rules.validate().then(async (success) => {
        if (success) {
          this.handleForm(id);
          this.$store.dispatch('app/getAllCustomer')
          this.newSearch = "";
        } else {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Please fill in all fields",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    async handleForm(id) {
      let endDate;

      switch (this.plans.id) {
        case 1:
          endDate = moment(this.originalDate, "DD/MM/YYYY")
            .add(1, "month")
            .format("YYYY/MM/DD");
          break;
        case 2:
          endDate = moment(this.originalDate, "DD/MM/YYYY")
            .add(3, "months")
            .format("YYYY/MM/DD");
          break;
        case 3:
          endDate = moment(this.originalDate, "DD/MM/YYYY")
            .add(6, "months")
            .format("YYYY/MM/DD");
          break;
        case 4:
          endDate = moment(this.originalDate, "DD/MM/YYYY")
            .add(9, "months")
            .format("YYYY/MM/DD");
          break;
        case 5:
          endDate = moment(this.originalDate, "DD/MM/YYYY")
            .add(12, "months")
            .format("YYYY/MM/DD");
          break;
        default:
          // Handle other cases or set a default behavior
          break;
      }

      const customerData = {
        f_name: this.fullname,
        gender: this.gender.id,
        goal_id: this.goal.id,
        height: this.height,
        current_weight: this.weight,
        email: this.email,
        phone: this.contact,
        language: "English",
        country: this.country.id,
        startdate: moment().format("YYYY-MM-DD"),
        enddate: endDate,
        plan_id: this.plans.id,
        password: this.password,
        confirm_password: this.confirm_password,
        trainer_id: id,
        // username: this.UserID,
        dob:moment(this.dob).format('YYYY-MM-DD'),
        goal_weight:this.goal_weight,
        purpose_of_workout:this.purpose_of_workout,
        injuries:this.injuries
      };


      if(this.goal && this.goal.name =='Gain Weight'){
        customerData.gain_weight_id = this.weekly_goal?this.weekly_goal.id:''
      }
      else if(this.goal && this.goal.name =='Lose weight'){
        customerData.lose_weight_id = this.weekly_goal?this.weekly_goal.id:''
      }

      this.submiting=true
      const requestOptions = {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}customer/add`,
        data: JSON.stringify(customerData),
      };

      await axios(requestOptions)
        .then((response) => {
          if (response.data.success === false) {
            // Display warning message for failure
            this.$swal({
              title: "Warning",
              timer: 5000,
              text: response.data.message
                ? `${response.data.message}`
                : "Please fill in all fields",
              icon: "warning",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.AddNewUser = true;
          this.assignTrainer = false;
          } else if (response.data.success === true) {
            // Display success message for success
            this.$swal({
              title: "Submitted",
              text: response.data.message
                ? `${response.data.message}`
                : response.data.success
                ? `${response.data.success}`
                : "User Added Successfully",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });

          this.AddNewUser = false;
          this.assignTrainer = false;
          }
          this.$forceUpdate();
          this.getGraphData();
          // this.$refs.myForm.reset();
          
        })
        .catch((error) => {
          let string = "";
          this.assignTrainer=false;
          this.AddNewUser=true;
          if (error.response?.data?.errors) {
            Object.values(error.response.data.errors).map((item) => {
              string += `${item[0]}\n`;
            });
          }
          if (error.response?.data?.message) {
            string = error.response.data.message;
          }
          this.$swal({
            title: "Error",
            text: error.response && error.response.data && error.response.data.message ?error.response.data.message :'' ,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          console.error("Error adding customer:", error);
        }).finally(()=>{
          this.submiting=false          
        })

      // .then((response) => {
      //   this.$swal({
      //     title: "Submitted",
      //     text: response.data.message
      //       ? `${response.data.message}`
      //       : response.data.success
      //       ? `${response.data.success}`
      //       : "User Added Successfully",
      //     icon: "success",
      //     customClass: {
      //       confirmButton: "btn btn-primary",
      //     },
      //     buttonsStyling: false,
      //   });
      //   this.$forceUpdate();
      //   this.getGraphData();
      //   this.$refs.myForm.reset();
      //   this.AddNewUser = false;
      //   this.assignTrainer = false;
      // })

      // .catch((error) => {
      //   let string = "";
      //   if (error.response.data.errors) {
      //     Object.values(error.response.data.errors).map((item) => {
      //       string += `${item[0]}\n`;
      //     });
      //   }
      //   if (error.response.data.message) {
      //     string = error.response.data.message;
      //   }
      //   this.$swal({
      //     title: "Error",
      //     html: string,
      //     icon: "error",
      //     customClass: {
      //       confirmButton: "btn btn-primary",
      //     },
      //     buttonsStyling: false,
      //   });

      //   console.error("Error adding customer:", error);
      // });
    },
    openModal() {
      this.AddNewUser = true;
      this.fullname = "";
      this.gender = "";
      this.goal = "";
      this.height = "";
      this.weight = "";
      this.email = "";
      this.contact = "";
      this.language = "";
      this.country = "";
      this.plans = "";
      this.password = "";
      this.confirm_password = "";
      this.id = 0;
      // this.UserID = "";  
      this.dob = moment().subtract(16,'year')._d
      this.purpose_of_workout=''
      this.injuries='';

      this.goal_weight='';
      this.weekly_goal=''
    },
    openTrainerModal() {
      this.TrainerAddNewTrainer = true;
      this.TrainerFullname = "";
      this.TrainerGender = "";
      this.TrainerAddress = "";
      this.TrainerGoal = "";
      this.TrainerHeight = "";
      this.TrainerWeight = "";
      this.TrainerEmail = "";
      this.TrainerContact = "";
      this.TrainerLanguage = "";
      this.TrainerCountry = "";
      this.TrainerPassword = "";
      this.TrainerConfirm_password = "";
      this.id = "";
      this.profileAttach = "";
      this.IDAttach = "";
      this.CertiAttach = "";
      this.Ptinsurance = "";
      this.TrainerSpecialized = "";
      this.TrainerPlans = "";
      // this.TrainerUserId = "";
      this.trainer_dob = moment().subtract(16,'year')._d

      this.selectedOptions=[]
    },
    handleNewPage() {
      this.$refs.Rules.validate().then(async (success) => {
        if (success == false || this.TrainerPasswordMismatch || this.TrainerEmail!==this.con_TrainerEmail || this.TrainerContact!==this.con_TrainerContact) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Please fill in all fields",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        else if (success) {
          if (this.TrainerAddNewTrainer) {
            this.TrainerAddNewTrainer = false;
          }
          this.SubmitTrainer = true;
        }
      });
    },
    handleTrainerSubmit() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: response.data.message
              ? `${response.data.message}`
              : "Please fill in all fields",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.handleTrainerForm();
          // this.TrainerAddNewTrainer = true
        }
        else{
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Please fill in all fields",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    async handleTrainerForm() {
      let endDate;
      
      switch (this.TrainerPlans.id) {
        case 1:
          endDate = moment(this.originalDate, "DD/MM/YYYY")
            .add(1, "month")
            .format("YYYY/MM/DD");
          break;
        case 2:
          endDate = moment(this.originalDate, "DD/MM/YYYY")
            .add(3, "months")
            .format("YYYY/MM/DD");
          break;
        case 3:
          endDate = moment(this.originalDate, "DD/MM/YYYY")
            .add(6, "months")
            .format("YYYY/MM/DD");
          break;
        case 4:
          endDate = moment(this.originalDate, "DD/MM/YYYY")
            .add(9, "months")
            .format("YYYY/MM/DD");
          break;
        case 5:
          endDate = moment(this.originalDate, "DD/MM/YYYY")
            .add(12, "months")
            .format("YYYY/MM/DD");
          break;
        default:
          // Handle other cases or set a default behavior
          break;
      }

      const TrainercustomerData = {
        f_name: this.TrainerFullname,
        gender: this.TrainerGender.id,
        address: this.TrainerAddress,
        height: this.TrainerHeight,
        weight: this.TrainerWeight,
        email: this.TrainerEmail,
        phone: this.TrainerContact,
        language: "English",
        country: this.TrainerCountry.id,
        password: this.TrainerPassword,
        confirm_password: this.TrainerConfirm_password,
        profilephoto: this.profileAttach,
        idproof: this.IDAttach,
        ptcertificate: this.CertiAttach,
        ptinsurance: this.PtInsurance,
        specialization: this.selectedOptions,
        plan_id: this.TrainerPlans.id,
        startdate: moment().format("YYYY-MM-DD"),
        enddate: endDate,
        // username: this.TrainerUserId,
        dob:moment(this.trainer_dob).format('YYYY-MM-DD')
      };
      const requestOptions = {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}trainer/add`,
        data: JSON.stringify(TrainercustomerData),
      };
      await axios(requestOptions)
        .then((response) => {
          if (response.data.success === false) {
            this.TrainerAddNewTrainer = true;
            this.SubmitTrainer = false;
            // Display warning message for failure
            this.$swal({
              title: "Warning",
              timer: 5000,
              text: response.data.message
                ? `${response.data.message}`
                : "Please fill in all fields",
              icon: "warning",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          } else if (response.data.success === true) {
            this.TrainerAddNewTrainer = false;
            this.SubmitTrainer = false;
            // Display success message for success
            this.$swal({
              title: "Submitted",
              text: response.data.message
                ? `${response.data.message}`
                : "Trainer Added Successfully",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          this.getGraphData();
          this.selectedOptions=[]
          }
        })
        .catch((error) => {
          // Display error message for any error
            this.TrainerAddNewTrainer = false;
            this.SubmitTrainer = false;
          this.$swal({
            title: "Error",
            text: error.response.data.message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          console.error("Error adding customer:", error);
        });
    },

    close() {
      this.AddNewUser = false;
      this.TrainerAddNewTrainer = false;
      this.SubmitTrainer = false;
    },
    CloseTrainer() {
      this.SubmitTrainer = false;
      this.TrainerAddNewTrainer = false;
      this.handelResetTrainer();
    },
    handleBackTrainer(){
      this.SubmitTrainer = false;
      this.TrainerAddNewTrainer = true;
    },
    handelResetTrainer() {
      this.f_name = "";
      this.gender = "";
      this.address = "";
      this.height = "";
      this.weight = "";
      this.email = "";
      this.phone = "";
      this.language = "";
      this.country = "";
      this.password = "";
      this.confirm_password = "";
      this.profilephoto = "";
      this.idproof = "";
      this.ptcertificate = "";
      this.ptinsurance = "";
      this.specialized = "";
      this.plan_id = "";
      this.startdate = "";
      this.enddate = "";
      this.username = "";
      this.goal_weight =''
      this.weekly_goal=''
      this.purpose_of_workout=''
      this.injuries=''
      this.dob = moment().subtract(16,'year')._d

    },
    openRenewalModal() {
      this.$router.push({ path: "/Plan-Renewal" });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/chart-apex.scss";

.dashboard-card {
  min-height: 150px;
}
.trainerList{
  max-height: 500px;
  overflow: auto;
}
</style>

