<template>
  <div>
    <!-- <user-list-add-new :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive" :role-options="roleOptions"
          :plan-options="ss" @refetch-data="refetchData" />
    
        <users-list-filters :role-filter.sync="roleFilter" :plan-filter.sync="planFilter" :status-filter.sync="statusFilter"
          :role-options="roleOptions" :plan-options="ss" :status-options="statusOptions" /> -->

    <b-card no-body class="mb-0">
      <div class="m-1">
        <!-- Table Top -->
        <b-row class="d-flex justify-content-between w-100">
          <!-- Per Page -->
          <b-col md="6" class="d-flex mt-0">
            <h4>Trainers</h4>
            <!-- Search -->
          </b-col>
          <b-col md="6" class="">
            <div class="w-100 d-flex justify-content-end">
              <div>
                <b-form-input
                  class="d-inline-block h-75"
                  placeholder="Search..."
                  v-model="search"
                  @input="handleSearch()"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :busy="isLoading"
        :items="TrainerData"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        @sort-changed="onSortChanged"
      >
        <template #table-busy>
          <div class="text-center text-secondary my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <!-- <template #thead-top="{ fields }">
                    <tr>
                      <th v-for="(field, index) in fields" :key="index">
                      <template v-if="field.key == 'Action'">
                          &nbsp;
                        </template>
                        <template v-else-if="field.type == 'dropdown'">
                          <v-select :options="getOptions('specialized')"
                            :label="field.displayName" 
                            v-model="columnFilters[field.key]"
                            multiple
                            @input="filterTable()"
                             />
                        </template>
                        <template v-else>
                          <b-form-input
                            v-model="columnFilters[field.key]"
                            @input="filterTable()"
                            placeholder="Search..."
                          />
                        </template>
                      </th>
                    </tr>
                  </template> -->
        <template #cell(f_name)="data">
          <div
            @click="openTrainerProfile(data.item)"
            class="font-weight-bolder d-flex"
            style="font-size: 15px; font-family: 'Public Sans', sans-serif"
          >
            <span class="mr-1">
              <b-avatar
                size="32"
                :src="data.item.profilephoto"
                :text="data.item.f_name.substring(0, 1)"
              />
            </span>
            {{ data.item.f_name }}
          </div>
        </template>

        <template #cell(action)="TrainerData">
          <div class="d-flex align-items-center">
            <feather-icon
              class="mr-2 cursor-pointer"
              icon="XIcon"
              size="25"
              @click="openRejectModal(TrainerData.item.id)"
            />
            <feather-icon
              icon="CheckIcon"
              size="25"
              class="cursor-pointer mr-2"
              @click="openModel(TrainerData.item.id)"
            />
            <feather-icon
            icon="EyeIcon"
            size="25"
            class="cursor-pointer"
            @click="$router.push(`/trainerprofile/view/${TrainerData.item.id}`)"
          />
          </div>
        </template>
      </b-table>
      <div>
        <b-modal
          v-model="RejectModal"
          no-close-on-backdrop
          hide-footer
          centered
          size="md"
        >
          <validation-observer ref="simple">
            <b-form ref="myFormMain">
              <b-row class="p-1">
                <b-col md="12" class="my-2 text-center">
                  <!-- {{ CustomerData }} -->
                  <h3
                    class="font-weight-bolder"
                    style="font-size:26px; font-family:Public Sans, sans's serif"
                  >
                    Reject Trainer
                  </h3>
                </b-col>
                <b-col cols="12">
                  <b-form-group>
                    <label class="font-weight-bold mt-1" for="h-first-name"
                      >Reason For Rejection</label
                    >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Reject reason"
                    >
                      <b-form-input
                        type="text"
                        v-model="reject"
                        maxlength="100"
                        placeholder="Reason For Rejection"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- @click="Congo" -->
                <b-col md="12" class="d-flex justify-content-center mt-2">
                  <b-button
                    type="submit"
                    variant="primary"
                    class="mr-1"
                  :disabled='saving'

                    @click.prevent="handleRejectForm()"
                    style="font-family: Public Sans"
                  >
                    Submit
                  </b-button>
                  <b-button
                    type="reset"
                    @click="CloseModal"
                    variant="primary"
                    style="font-family: Public Sans"
                  >
                    Cancel
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-modal>
      
      </div>
      <div>
        <b-modal
          v-model="selectPlan"
          no-close-on-backdrop
          hide-footer
          centered
          size="md"
        >
          <validation-observer ref="simpleRules">
            <b-form ref="myFormMain">
              <b-row class="p-1">
                <b-col md="12" class="my-2 text-center">
                  <h3
                    style="
                      font-weight: 600;
                      font-size: 26px;
                      font-family: Public Sans;
                    "
                  >
                  Approve Trainer
                  </h3>
                </b-col>
                <!-- <b-col cols="12">
                  <b-form-group>
                    <label class="font-weight-bold mt-1" for="h-first-name"
                      >Create UserID</label
                    >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="UserID"
                    >
                      <b-form-input
                        type="text"
                        v-model="UserID"
                        maxlength="20"
                        placeholder="UserID"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->
                <b-col cols="12">
                  <b-form-group>
                    <label class="font-weight-bold mt-1" for="h-contact"
                      >Password</label
                    >
                    <validation-provider
                      #default="{ errors }"
                      rules="required|password"
                      name="password"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          id="h-contact"
                          maxlength="20"
                          v-model="password"
                          :type="passwordIcon"
                          autocomplete="new-password"
                          name="Password"
                          placeholder="Password"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePassword"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small v-if="passwordMismatch == true" class="text-danger"
                        >Password and Confirm Password must be same.</small
                      >
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group>
                    <label class="font-weight-bold mt-1" for="h-contact"
                      >Confirm Password</label
                    >
                    <validation-provider
                      #default="{ errors }"
                      rules="required|password"
                      name="Confirm"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          id="h-contact"
                          maxlength="20"
                          v-model="confirm_password"
                          :type="confirmpasswordIcon"
                          autocomplete="new-password"
                          name="Confirm Password"
                          placeholder="Confirm Password"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="ConfirmIcon"
                            @click="toggleConfirmPassword"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small v-if="passwordMismatch" class="text-danger"
                        >Password and Confirm Password must be same.</small
                      >
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <label for="input">Select Plans</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Select Plan"
                  >
                    <v-select
                      placeholder="Select Your Plans"
                      label="name"
                      :searchable="false"
                      :options="getOptions('plan')"
                      v-model="plans"
                    ></v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>

                <!-- @click="Congo" -->
                <b-col md="12" class="d-flex justify-content-center mt-2">
                  <b-button
                    type="submit"
                    variant="primary"
                    class="mr-1"
                    :disabled='saving'

                    @click.prevent="handleFormSubmission()"
                    style="font-family: Public Sans"
                  >
                    Submit
                  </b-button>
                  <b-button
                    type="reset"
                    @click="CloseModal()"
                    variant="primary"
                    style="font-family: Public Sans"
                  >
                    Cancel
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-modal>
      </div>
      <!-- ApproveTrainer('Approved', TrainerData.item.id) -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          </b-col>
          <!-- Pagination -->
          <div class="w-100 d-flex justify-content-between p-1">
            <!-- Pagination -->
            <div>
              <span class="text-nowrap">
                Showing {{ pagination.current_page }} to
                {{ pagination.per_page }} of {{ pagination.total }}</span
              >
            </div>
            <b-pagination
              :total-rows="paginationTotal"
              :per-page="per_page"
              v-model="paginationValue"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="changePagination()"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-row>
      </div>
    </b-card>
  </div>
</template>


<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BProgress,
  BFormGroup,
  BFormFile,
  BForm,
  BInputGroupAppend,
  BInputGroup,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "@/component/axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import _ from "lodash";
import moment from "moment";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BSpinner,
    required,
    BRow,
    moment,
    BForm,
    BInputGroupAppend,
    BInputGroup,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BPagination,
    BProgress,
    vSelect,
    BFormFile,
  },
  data() {
    return {
      columnFilters: {},
      // tableColumns: ['ID', 'Trainer', 'Client', 'SpecializedInWhichArea', 'Contact', 'Action'],
      tableColumns: [
        { key: "f_name", label: "Trainers", sortable: true },
        // { key: 'total', sortable: true, formatter: val => `$${val}` },
        {
          key: "specialized",
          label: "Specialized In Which Area",
          type: "dropdown",
          displayName: "name",
        },
        // { key: 'balance', sortable: true },
        { key: "Action", label: "Action" },
      ],
      searchTerm: { name: "Trainer", value: "trainer" },
      search: "",
      sortBy: "",
      orderBy: true ? "ASC" : "DESC",
      isSortDirDesc: true,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      isLoading: false,
      pagination: "",
      TrainerData: [],
      saving:false,
      selectPlan: false,
      plans: "",
      originalDate:moment().format("DD/MM/YYYY"),
      paginationStart: 0,
      paginationEnd: 0,
      paginationValue: 1,
      paginationTotal: 0,
      per_page: 10,
      selectedId: 0,
      password: "",
      passwordIcon: "password",
      // UserID: "",
      confirm_password: "",
      confirmpasswordIcon: "password",
      passwordMismatch: false,
      RejectModal: false,
      reject: "",
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordIcon === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    ConfirmIcon() {
      return this.confirmpasswordIcon === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  watch: {
    password() {
      this.validatePasswordMatch();
    },
    confirm_password() {
      this.validatePasswordMatch();
    },
  },
  mounted() {
    this.getRecentTrainer();
  },
  methods: {
    onSortChanged(e) {
      this.sortBy = e.sortBy;
      this.orderBy = e.sortDesc ? "DESC" : "ASC";
      // console.log(this.sortBy)
      // console.log(this.orderBy)
      this.getRecentTrainer();
    },
    // filterTable: _.debounce(function () {
    //   this.params = {};
    //   for (let key in this.columnFilters) {
    //     if (
    //       this.columnFilters[key] &&
    //       typeof this.columnFilters[key] == "object"
    //     ) {
    //       this.params[key] = this.columnFilters[key]
    //         .map((inner) => inner.id)
    //         .join(",");
    //     } else {
    //       this.params[key] = this.columnFilters[key];
    //     }
    //   }
    //   this.params.page = this.paginationValue;
    //   this.getRecentTrainer();
    // }, 1000),

    validatePasswordMatch() {
      if (this.password !== this.confirm_password) {
        this.passwordMismatch = true;
      } else {
        this.passwordMismatch = false;
      }
    },
    togglePassword() {
      this.passwordIcon =
        this.passwordIcon === "password" ? "text" : "password";
    },
    toggleConfirmPassword() {
      this.confirmpasswordIcon =
        this.confirmpasswordIcon === "password" ? "text" : "password";
    },
    handleRejectForm() {
      this.$refs.simple.validate().then(async (success) => {
        if (success) {
          this.RejectClient("Rejected", this.selectedId);
        }
      });
    },
    RejectClient(e, id) {
      const customerData = {
        approve: e,
        id: id,
        rejectreason: this.reject,
      };
      this.saving = true;
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}dashboard/request-approval`,
        data: JSON.stringify(customerData),
      };
      axios(requestOptions)
        .then((response) => {
          let messageToShow = "";

          if (e === "Rejected") {
            messageToShow = "Client Rejected successfully";
          } else {
            messageToShow =
              response.data.message || "Client approved Successfully";
          }
          this.$swal({
            title: "Submitted",
            text: messageToShow,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.RejectModal = false;
          this.getRecentTrainer();
          this.$forceUpdate();
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.$swal({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
              timer: 5000,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          } else {
            this.$swal({
              title: "Error!",
              text: `${error}`,
              icon: "error",
              timer: 5000,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
        })
        .finally(()=>{
          this.saving=false
        })
    },
    handleFormSubmission() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          this.ApproveTrainer("Approved", this.selectedId);
        }
      });
    },
    ApproveTrainer(e, id) {
      let endDate;

      switch (this.plans.id) {
          case 1:
            endDate = moment(this.originalDate, "DD/MM/YYYY")
              .add(1, "month")
              .format("YYYY/MM/DD");
            break;
          case 2:
            endDate = moment(this.originalDate, "DD/MM/YYYY")
              .add(3, "months")
              .format("YYYY/MM/DD");
            break;
          case 3:
            endDate = moment(this.originalDate, "DD/MM/YYYY")
              .add(6, "months")
              .format("YYYY/MM/DD");
            break;
          case 4:
            endDate = moment(this.originalDate, "DD/MM/YYYY")
              .add(9, "months")
              .format("YYYY/MM/DD");
            break;
          case 5:
            endDate = moment(this.originalDate, "DD/MM/YYYY")
              .add(12, "months")
              .format("YYYY/MM/DD");
            break;
          default:
            // Handle other cases or set a default behavior
            break;
        }
        this.saving=true

      const data = {
        approve: e,
        id: id,
        startdate: moment().format("YYYY-MM-DD"),
        enddate: endDate,
        plan_id: this.plans.id,
        password: this.password,
        // username: this.UserID,
        confirm_password: this.confirm_password,
      };
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}dashboard/request-approval`,
        data: JSON.stringify(data),
      };
      axios(requestOptions)
        .then((response) => {
          this.$swal({
            title: "Submitted",
            text: response.data.message
              ? `${response.data.message}`
              : response.data.success
              ? `${response.data.success}`
              : "Trainer Approve Successfully",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.selectPlan = false;
          this.getRecentTrainer();
          this.$forceUpdate();
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            this.$swal({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
              timer: 5000,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          } else {
            this.$swal({
              title: "Error!",
              text: `${error}`,
              icon: "error",
              timer: 5000,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
        })
        .finally(()=>{
          this.saving=false

        })
    },
    changePagination() {
      this.getRecentTrainer();
    },
    async getRecentTrainer() {
      this.isLoading = true;
      let params = {};
      params.page = this.paginationValue;
      params.per_page = this.per_page;
      if (this.search) {
        params.search = this.search.id ? this.search.id : this.search;
        params.type = this.searchTerm.value;
      }
      if (this.sortBy) {
        params.sortBy = this.sortBy;
        params.orderBy = this.orderBy;
      }
      // let params = `?page=${this.paginationValue}&per_page=${this.per_page}${
      //   this.search ? "&search=" + this.search : ""
      // }${
      //   this.search && this.searchTerm ? "&type=" + this.searchTerm.value : ""
      // }`;
      await axios({
        method: "GET",
        url: `${this.baseApi}dashboard/get-recent-trainer`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        params: params,
      })
        .then((json) => {
          this.TrainerData = json?.data.data.trainers;
          this.pagination = json?.data.data.pagination;
          this.paginationTotal = json?.data.data.pagination.total;
          this.paginationStart = json?.data.data.pagination.from;
          this.paginationEnd = json?.data.data.pagination.to;
          this.TrainerData.map((item)=>{
              let arr = item.all_specialization.map(inner=> inner.specialization?inner.specialization.name:'');
              arr=arr.filter(item=>item)
              item.specialized =arr.join(', ')
            })
        })
        .catch((error) => console.log(error, "error"));
      this.isLoading = false;
    },
    handleSearch: _.debounce(function () {
      this.getRecentTrainer();
    }, 1000),
    openModel(id) {
      this.selectedId = id;
      this.selectPlan = true;
      // this.UserID = "";
      this.password = "";
      this.confirm_password = "";
      this.plans = "";
    },
    openRejectModal(id) {
      this.RejectModal = true;
      this.selectedId = id;
      this.reject = "";
    },
    CloseModal() {
      this.selectPlan = false;
      this.RejectModal = false;
    },
  },
};
</script>
<style>
.avatar-container {
  display: flex;
  align-items: center;
}

.remaining-count {
  margin-left: 5px;
  font-weight: bold;
}

body {
  font-family: "Public Sans", sans-serif;
  font-family: 15px;
}

.option {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 5px;
}

.option.selected {
  border: 1px solid #7367f0;
  color: #000;
}
</style>
