<template>
  <div>
    <b-card class="earnings-card" style="box-shadow: -18px 20px 12px -5px rgba(0,0,0,0.1);">
      <b-row>

        <b-col cols="6">
          <b-card-header>
            <div>
              <b-card-title class="mb-1">
                {{ title }}
              </b-card-title>
              <b-card-sub-title>Monthly Report</b-card-sub-title>
            </div>
          </b-card-header>

          <h2 class="mb-1 mt-2 ml-2">
            {{ lastMonth }}
          </h2>
          <b-card-text class="text-success font-small-5">
            <span class="font-weight-bolder">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-arrow-up-circle" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                  d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
              </svg>
            </span><span class="ml-1">{{percent}}%</span>
          </b-card-text>
        </b-col>
        <b-col cols="6">
          <!-- chart -->
          <vue-apex-charts :options="earningsChart.chartOptions" :series="earningsChart.series" />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BCardTitle, BCardText, BCardHeader, BCardSubTitle
} from 'bootstrap-vue'
import axios from '@/component/axios';
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

const $earningsStrokeColor2 = '#28c76f66'
// const $earningsStrokeColor3 = '#28c76f33'
export default {
  props: ['title', 'lastMonth','currentMonth',],
  computed: {
    totalLabelText() {
      return ` ${this.currentMonth??0}`;
    },
    percent() {
      const percentage = ((this.currentMonth) * 100 / (this.lastMonth));
      
      const positivePercentage = Math.abs(percentage)
      return positivePercentage?positivePercentage.toFixed(2) : 0;
    }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle, BCardSubTitle,
    BCardText,
    VueApexCharts, BCardHeader
  },
  data() {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      isSideDataLoading: false,
      PieChartData: '',
      allCustomer: {},
      accessToken: localStorage.getItem("accessToken"),

      earningsChart: {
  series: [100],
  chartOptions: {
    chart: {
      type: 'donut',
      height: 150,
    },
    tooltip: {
            enabled: false,
           },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
      position: 'bottom',
    },
    comparedResult: [8],
    stroke: { width: 0 },
    colors: ['#28C76F'],
    plotOptions: {
      pie: {
        startAngle: 0,
        donut: {
          labels: {
            show: true,
            name: {
              offsetY: 15,
            },
            value: {
              offsetY: -15,
              formatter(val) {
                return `${parseInt(val)}`;
              },
            },
            total: {
              show: true,
              offsetY: 15,
              label: 'Total',
              formatter: () => this.totalLabelText,
            },
          },
        },
      },
    },
    labels: [], // Empty array to remove series labels
    responsive: [
      {
        breakpoint: 1325,
        options: {
          chart: {
            height: 100,
          },
        },
      },
      {
        breakpoint: 1200,
        options: {
          chart: {
            height: 120,
          },
        },
      },
      {
        breakpoint: 1045,
        options: {
          chart: {
            height: 100,
          },
        },
      },
      {
        breakpoint: 992,
        options: {
          chart: {
            height: 120,
          },
        },
      },
    ],
  },
},
    }
  },
  mounted() {

  },
}
</script>

